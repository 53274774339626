
import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonContent,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSpinner,
  IonTextarea,
  alertController,
  toastController,
  onIonViewWillEnter,
} from '@ionic/vue';

import {
  sendSharp,
  trashOutline,
  personCircleOutline,
  flagOutline,
  flag,
  shareSocialOutline,
} from 'ionicons/icons';
import { computed, ComputedRef, defineComponent, inject, Ref, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { throttle } from 'lodash';
import axios from 'axios';

import { getAlertController } from '@/common/reportUserAlert';
import VoteButton from '@/components/VoteButton.vue';
import GradeSlider from '@/components/GradeSlider.vue';
import MessageBox from '@/components/MessageBox.vue';
import { shareSocial } from '@/common/shareSocial';
import getRouteDetails, {Comment, RouteDetails} from '@/common/api/route/getRouteDetails';
import addCommentToRoute from '@/common/api/route/addCommentToRoute';
import changeRouteGrade from '@/common/api/route/changeRouteGrade';
import {checkAgainstUnauthorizedResponse} from "@/common/utils";
import {toFootholdName} from "@/common/api/footholdType";

export default defineComponent({
  name: 'ViewRoute',
  components: {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonIcon,
    IonImg,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
    IonSpinner,
    IonTextarea,
    GradeSlider,
    MessageBox,
    VoteButton,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const username = computed(() => route.params.username as string);
    const createdAt = computed(() => route.params.createdAt as string);
    const getLoggedIn: () => Ref<boolean> = inject('getLoggedIn', () => ref(false));
    const getUsername: () => Ref<string> = inject('getUsername', () => ref(''));
    const getAccessToken: () => Ref<string> = inject('getAccessToken', () => ref(''));
    const getUserRole: () => ComputedRef<string> = inject('getUserRole', () => computed(() => ''));
    const commentText = ref('');

    const asciiPattern = /^[ -~]+$/;
    const msgBox: Ref<typeof MessageBox | null> = ref(null);

    const myUsername = getUsername();
    const isLoggedIn = getLoggedIn();
    const isAdmin = computed(() => getUserRole().value === 'admin');
    const isRouteSetter = computed(() => username.value === myUsername.value);
    const hasAlreadyCommented = ref(false);
    const hasReported = ref(false);

    const isLoading = ref(false);

    let routeDetails: Ref<RouteDetails | null> = ref(null);

    const updateRouteDetails = throttle(async (showLoadingScreen = true) => {
      if (isLoading.value) {
        return;
      }
      isLoading.value = showLoadingScreen;
      try {
        const data = await getRouteDetails(username.value, createdAt.value);
        if (data.Message === 'Get route details success') {
          routeDetails.value = data.Item;
          hasAlreadyCommented.value =
            routeDetails.value?.comments.some((comment) => comment.username == myUsername.value) ??
            false;
          hasReported.value = routeDetails.value?.hasReported ?? false;
        } else {
          throw new Error('Failed to get route details');
        }
      } catch (error:any) {
        checkAgainstUnauthorizedResponse(error);
        console.error(error);
        router.back();
      } finally {
        isLoading.value = false;
      }
    }, 1000);

    updateRouteDetails();

    /** This fixes the issue of the route details not updating when navigating between this view and the explore view */
    onIonViewWillEnter(updateRouteDetails);

    const postCommentHandler = throttle(async () => {
      msgBox.value?.close();
      const comment = commentText.value.trim();
      commentText.value = '';
      if (comment.length === 0) {
        msgBox.value?.showMsg('Review cannot be empty');
        return false;
      }
      if (comment.length > 150) {
        msgBox.value?.showMsg('Review is too long, please keep it within 150 characters');
        return false;
      }
      if (!asciiPattern.test(comment)) {
        msgBox.value?.showMsg('Review cannot contain non-ASCII characters');
        return false;
      }
      
      if(!routeDetails.value?.id){
        msgBox.value?.showMsg('Waiting for route details, retry in some seconds!');
        return false;
      }

      hasAlreadyCommented.value = true;
      try {
        const data = await addCommentToRoute(routeDetails.value.id, comment);
        if (data.Message === 'Comment route success') {
          // Add as the first comment in the list
          routeDetails.value?.comments.unshift(data.Item);
        } else {
          hasAlreadyCommented.value = false;
          throw new Error('Failed to add comment');
        }
      } catch (error:any) {
        msgBox.value?.showMsg('Please try again in a while');
        console.error(error);
      }

      return true;
    }, 1000);

    const deleteCommentHandler = throttle(async (commentDetails:Comment) => {
      msgBox.value?.close();
      const alert = await alertController.create({
        header: `Delete review?`,
        message: 'Are you sure?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Delete',
            cssClass: 'global-danger-text',
            handler: async () => {
              try {
                const response = await axios.delete(
                  process.env.VUE_APP_ROUTE_ENDPOINT_URL + 'v2/comments/' + commentDetails.id,
                  {
                    headers: {
                      Authorization: `Bearer ${getAccessToken().value}`,
                    }
                  },
                );
                if (response.status === 204) {
                  if (routeDetails.value) {
                    // Username may be different from commentUsername when it is an admin delete
                    routeDetails.value.comments = routeDetails.value?.comments.filter((comment) => {
                      return (
                        comment !== commentDetails
                      );
                    });
                    // If the user deletes his own comment
                    if (commentDetails.username === myUsername.value) {
                      hasAlreadyCommented.value = false;
                    }
                  }
                }
              } catch (error:any) {
                msgBox.value?.showMsg('Please try again in a while');
                console.error(error);
              }
            },
          },
        ],
      });
      return alert.present();
    }, 1000);

    const reportCommentHandler = throttle(async (commentUsername: string) => {
      const alert = await getAlertController(commentUsername, getAccessToken().value);
      return alert.present();
    }, 1000);

    const reportRouteHandler = throttle(async (routeUsername: string, createdAt: string) => {
      const alert = await alertController.create({
        cssClass: 'global-wide',
        header: `Report this route?`,
        message: 'Are you sure?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Yes',
            handler: async () => {
              try {
                await axios.post(
                  process.env.VUE_APP_ROUTE_ENDPOINT_URL + '/v1/route/details/report',
                  {
                    username: routeUsername,
                    createdAt,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${getAccessToken().value}`,
                    },
                  },
                );
                hasReported.value = true;
                const toast = await toastController.create({
                  header: 'Your report has been successfully sent',
                  position: 'bottom',
                  color: 'success',
                  duration: 3000,
                  buttons: [
                    {
                      text: 'Close',
                      role: 'cancel',
                    },
                  ],
                });
                toast.present();
              } catch (error:any) {
                console.error(error);
                const toast = await toastController.create({
                  header: 'Failed to report route, please try again',
                  position: 'bottom',
                  color: 'danger',
                  duration: 3000,
                  buttons: [
                    {
                      text: 'Close',
                      role: 'cancel',
                    },
                  ],
                });
                toast.present();
              }
            },
          },
        ],
      });
      return alert.present();
    }, 1000);

    const gradeChangeHandler = throttle(async (grade: number) => {
      msgBox.value?.close();
      if (!isLoggedIn.value) {
        return;
      }
      try {
        if (routeDetails.value) {
          if(isRouteSetter.value){
            const data = await changeRouteGrade(routeDetails.value.id, grade);
            if (data.Message === 'Grade route success') {
              routeDetails.value.graded = grade;
              routeDetails.value.ownerGrade = data.Item.ownerGrade;
              routeDetails.value.publicGrade = data.Item.publicGrade;
            }
          }
          else {
            const headers = {
              Authorization: `Bearer ${getAccessToken().value}`,
              accept: 'application/json'
            };
            
            const response = routeDetails.value.graded == -1
                ? await axios.post(process.env.VUE_APP_ROUTE_ENDPOINT_URL + 'v2/public_grade_submissions', { grade:grade, route: routeDetails.value.id }, { headers })
                : await axios.post(process.env.VUE_APP_ROUTE_ENDPOINT_URL + 'v2/public_grade_submissions/update', { grade:grade, route: routeDetails.value.id }, {headers});
            
            if(response.data.id){
              updateRouteDetails();
            }
          }
        }
      } catch (error:any) {
        msgBox.value?.showMsg('Please try again in a while');
        console.error(error);
      }
    }, 500);

    const sharePostHandler = async () => {
      await shareSocial(route, `Route by ${username.value}`);
    };

    return {
      router,
      routeDetails,
      commentText,
      hasAlreadyCommented,
      postCommentHandler,
      trashOutline,
      personCircleOutline,
      shareSocialOutline,
      myUsername,
      deleteCommentHandler,
      reportCommentHandler,
      sendSharp,
      isLoggedIn,
      isAdmin,
      flag,
      flagOutline,
      reportRouteHandler,
      hasReported,
      msgBox,
      gradeChangeHandler,
      sharePostHandler,
      isRouteSetter,
      isLoading,
      toFootholdName
    };
  },
});
