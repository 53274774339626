import axios from 'axios';
import Providers from '@/providers';
import cacheManager from 'cache-manager';
import {flatOutRoute} from "@/common/utils";

const memoryCache = cacheManager.caching({
  store: 'memory',
  max: 10, // Number of items in cache
  ttl: 1, // Seconds
});
// Set as 1 second to reduce duplicate calls by vue
// Must be small or will be unresponsive to upvotes

const getRouteDetailsUrl = process.env.VUE_APP_ROUTE_ENDPOINT_URL + 'v2/routes';

const getRouteDetails = async (username: string, createdAt: string): Promise<ResponseData> => {
  const headers = {
    Authorization: `Bearer ${Providers.getAccessToken().value}`,
    accept: 'application/json'
  }
  const response = await axios.get(getRouteDetailsUrl, { headers, params: { 'user.username': username, createdAt }});
  
  const data =  {
    Message: Array.isArray(response.data) ? 'Get route details success' : 'Something went wrong'
    , Item: flatOutRoute(response.data[0])
  } as ResponseData;
  
  const comments = await axios.get(process.env.VUE_APP_ROUTE_ENDPOINT_URL + 'v2/comments', { headers, params: { 'route.id': data.Item.id } });
  data.Item.comments = comments.data;
  
  // Comments with larger timestamps at the start of list
  data.Item.comments.sort((a, b) => b.timestamp - a.timestamp);
  return data;
};

// Caches in-memory, disappears on page refresh
const getRoutesDetailsCached = async (
  username: string,
  createdAt: string,
): Promise<ResponseData> => {
  // createdAt attribute only should be sufficient for 1 sec cache
  return memoryCache.wrap(createdAt, function () {
    return getRouteDetails(username, createdAt);
  });
};

interface ResponseData {
  Message: string;
  Item: RouteDetails;
}

interface Comment {
  id:number;
  username: string;
  timestamp: number;
  comment: string;
}

interface RouteDetails {
  id:number,
  comments: Comment[];
  countryCode: string;
  createdAt: string;
  graded: number;
  footholdType:number;
  gymLocation: string;
  hasGraded: boolean;
  hasReported: boolean;
  hasVoted: boolean;
  ownerGrade: number;
  publicGrade: number;
  routeName: string;
  description:string;
  routeURL: string;
  username: string;
  voteCount: number;
}

export default getRoutesDetailsCached;
export { RouteDetails, Comment };
