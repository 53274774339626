
import { IonRange } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GradeSlider',
  components: {
    IonRange,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    changeHandler: {
      type: Function,
      required: false,
      default: () => undefined,
    },
  },
  setup() {
    const range = (start: number, end: number) => {
      return Array.from({ length: end - start + 1 }, (_, i) => i);
    };
    return {
      range,
    };
  },
});
