import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-83350e8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "range" }
const _hoisted_2 = { class: "sliderticks" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_range = _resolveComponent("ion-range")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_range, {
      class: "slider",
      type: "range",
      min: "0",
      max: "14",
      step: "1",
      value: 14 - _ctx.value,
      disabled: _ctx.disabled,
      snaps: "",
      ticks: false,
      debounce: "600",
      onIonChange: _cache[0] || (_cache[0] = (changeEvent) => _ctx.changeHandler(14 - changeEvent.detail.value))
    }, null, 8, ["value", "disabled"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.range(0, 14).reverse(), (index) => {
        return (_openBlock(), _createElementBlock("p", { key: index }, _toDisplayString(index), 1))
      }), 128))
    ])
  ]))
}