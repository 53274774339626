import axios from 'axios';
import Providers from '@/providers';
import cacheManager from 'cache-manager';
import {flatOutRoute} from "@/common/utils";

const memoryCache = cacheManager.caching({
  store: 'memory',
  max: 10, // Number of items in cache
  ttl: 1, // Seconds
});
// Set as 1 second to reduce duplicate calls by vue
// Must be small or will be unresponsive to upvotes

const changeGradeUrl = process.env.VUE_APP_ROUTE_ENDPOINT_URL + 'v2/routes/';

const changeRouteGrade = async (
  id: number,
  grade: number,
): Promise<ResponseData> => {
  const headers = {
    Authorization: `Bearer ${Providers.getAccessToken().value}`,
    'Content-Type': 'application/merge-patch+json'
  };
  
  const response = await axios.patch(changeGradeUrl + id, { ownerGrade:grade }, { headers });

  return {
    Message: response.data.id ? 'Grade route success' : 'Something went wrong'
    , Item: flatOutRoute(response.data)
  } as ResponseData;
};

// Caches in-memory, disappears on page refresh
const changeRouteGradeCached = async (
  id: number,
  grade: number,
): Promise<ResponseData> => {
  // createdAt attribute only should be sufficient for 1 sec cache
  return memoryCache.wrap(id.toString(), function () {
    return changeRouteGrade(id, grade);
  });
};

interface ResponseData {
  Message: string;
  Item: {
    publicGrade: number;
    ownerGrade: number;
  };
}

export default changeRouteGradeCached;
