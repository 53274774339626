import axios from 'axios';
import Providers from '@/providers';
import { Comment } from './getRouteDetails';

const addCommentUrl = process.env.VUE_APP_ROUTE_ENDPOINT_URL + 'v2/comments';

const addCommentToRoute = async (
  id: number,
  comment: string,
): Promise<ResponseData> => {
  const headers = {
    Authorization: `Bearer ${Providers.getAccessToken().value}`,
  };
  const response = await axios.post(addCommentUrl, { comment, route: id }, { headers });

  return {
    Message: response.data.id ? 'Comment route success' : 'Something went wrong'
    , Item: response.data
  } as ResponseData;
};

interface ResponseData {
  Message: string;
  Item: Comment;
}

export default addCommentToRoute;
